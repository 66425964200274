// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const OutlineQuestionIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <g>
        <path
          d="M6 1.125C5.03582 1.125 4.09329 1.41091 3.2916 1.94659C2.48991 2.48226 1.86507 3.24363 1.49609 4.13442C1.12711 5.02521 1.03057 6.00541 1.21867 6.95107C1.40678 7.89672 1.87108 8.76536 2.55286 9.44715C3.23464 10.1289 4.10328 10.5932 5.04894 10.7813C5.99459 10.9694 6.97479 10.8729 7.86558 10.5039C8.75637 10.1349 9.51775 9.51009 10.0534 8.7084C10.5891 7.90671 10.875 6.96418 10.875 6C10.8736 4.70749 10.3596 3.46831 9.44564 2.55436C8.5317 1.64042 7.29251 1.12636 6 1.125ZM6 9C5.88875 9 5.78 8.96701 5.68749 8.9052C5.59499 8.84339 5.52289 8.75554 5.48032 8.65276C5.43775 8.54998 5.42661 8.43688 5.44831 8.32776C5.47001 8.21865 5.52359 8.11842 5.60225 8.03975C5.68092 7.96109 5.78115 7.90751 5.89026 7.88581C5.99938 7.8641 6.11248 7.87524 6.21526 7.91782C6.31804 7.96039 6.4059 8.03249 6.4677 8.12499C6.52951 8.21749 6.5625 8.32625 6.5625 8.4375C6.5625 8.58668 6.50324 8.72976 6.39775 8.83525C6.29226 8.94074 6.14919 9 6 9ZM6.375 6.71625V6.75C6.375 6.84946 6.33549 6.94484 6.26517 7.01517C6.19484 7.08549 6.09946 7.125 6 7.125C5.90055 7.125 5.80516 7.08549 5.73484 7.01517C5.66451 6.94484 5.625 6.84946 5.625 6.75V6.375C5.625 6.27554 5.66451 6.18016 5.73484 6.10983C5.80516 6.03951 5.90055 6 6 6C6.62016 6 7.125 5.57812 7.125 5.0625C7.125 4.54688 6.62016 4.125 6 4.125C5.37985 4.125 4.875 4.54688 4.875 5.0625V5.25C4.875 5.34946 4.83549 5.44484 4.76517 5.51516C4.69484 5.58549 4.59946 5.625 4.5 5.625C4.40055 5.625 4.30516 5.58549 4.23484 5.51516C4.16451 5.44484 4.125 5.34946 4.125 5.25V5.0625C4.125 4.13203 4.96594 3.375 6 3.375C7.03406 3.375 7.875 4.13203 7.875 5.0625C7.875 5.87719 7.23 6.55922 6.375 6.71625Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
});

export const OutlineQuestionIconMetadata = {
  name: 'Outline Question',
  importName: 'OutlineQuestionIcon',
  component: <OutlineQuestionIcon aria-label="Outline Question Icon" />,
  tags: 'question, ?, info, information, outline, circle, round, attention, help, support, tooltip, hint, tip, note',
};
